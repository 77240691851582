.caption-editor-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;

  .caption-editor {
    min-width: 300px;
    display: inline-block;
    width: auto;

    &.ltr {
      text-align: left;
      direction: ltr;
    }
    &.rtl {
      text-align: right;
      direction: rtl;
    }
  }
}