@import './global';

.timeline {
  display: grid;
  height: 20px;
  transition: 0.2s;

  .progress,
  .time-text-wrapper {
    grid-row: 1;
    grid-column: 1;
  }

  .progress {
    height: 100%;
    cursor: pointer;

    .progress-bar {
      transition: 0.2s !important;
      background-color: $gray-800;
      height: 100%;
    }

    .cursor {
      width: 10px;
      margin-left: -10px;
      transform: translateX(5px);
      height: 100%;
      background-color: $white;
      border: 3px solid $gray-800;
      border-radius: 5px;
      opacity: 0;
      transition: 0.2s;
    }
  }

  &:hover {
    .cursor {
      opacity: 1;
    }

    .time-text-wrapper {
      opacity: 1;
      transform: translateY(-100%);
    }
  }

  .time-text-wrapper {
    width: 100%;
    height: 100%;
    user-select: none;
    opacity: 0;
    transition: 0.2s;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    overflow: hidden;

    .time-text {
      display: inline-block;
      padding: 0 4px;
      background-color: $gray-800;
      color: $white;
      font-weight: bold;
      border-radius: 5px;
    }
  }
}
