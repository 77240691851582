@import './global';

.video-player {
  max-width: 100%;
  border-radius: 10px;
  overflow: hidden;

  .video-screen {
    display: grid;
    max-width: 100%;

    video,
    .video-state-controller {
      max-width: 100%;
      grid-row: 1;
      grid-column: 1;
      z-index: 0;
    }

    .video-state-controller {
      cursor: none;
      display: flex;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.25);
      opacity: 0;
      transition: 0.2s;

      & > .part {
        display: flex;
        justify-content: center;
        align-content: center;
        height: 33.33%;
        width: 100%;
      }

      .video-source-selection {
        margin-top: auto;

        .source-selection {
          padding: 2px 4px;
          font-size: 16px;
          color: $white;
          background-color: $gray-800;
          cursor: pointer;
          transition: 0.2s;

          &.active {
            background-color: $white !important;
            color: $gray-800 !important;
          }

          &:hover {
            background-color: $gray-500;
            color: $white;
          }
        }
      }

      .action-btn-group {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .forward {
          order: 3;
        }

        .play-pause {
          order: 2;
        }

        .backward {
          order: 1;
        }

        .action-btn {
          display: flex;
          height: 100%;
          border-radius: 50%;
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
          transition: 0.2s;
          cursor: pointer;

          .icon {
            color: $white;
            margin: auto;
          }

          &:hover {
            text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
            transform: scale(1.5);
          }
        }
      }
    }
  }

  &.show {
    .video-screen {
      .video-state-controller {
        cursor: default;
        opacity: 1 !important;
      }
    }
  }
}
