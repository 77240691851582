@import './global';

.time-control-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .time-text,
  .time-text-editor {
    font-family: 'digital';
    text-align: center;
    font-size: 18px;
    min-width: 40px;
    max-width: 100px;
  }

  .time-text {
    margin: 0 6px;
  }

  .time-text-editor {
    &:focus {
      outline: none;
      border: none;
      border-bottom: 1px solid $orange;
    }
  }

  .time-control-btn.little {
    transform: scale(0.75);
  }
}
