.input-upload {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  transition: border-color 0.3s;
  text-align: center;
  margin-top: 20px;
  padding: 16px;

  span {
    place-content: center;
  }
  .ant-upload-drag-icon {
    margin-bottom: 16px;
    span {
      color: #1677ff;
      font-size: 48px;
    }
  }
  .ant-upload-hint {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .ant-upload-text {
    margin: 0 0 4px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
  }
}
