@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  font-family: 'Noto Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
:focus-visible,
.rowOutlineFix:focus,
.rowOutlineFix:focus-visible,
button:focus {
  outline: none !important;
}
.icons_flag {
  height: 14px !important;
  padding-right: 3px;
}
.hidden {
  display: none;
}
.video-card .ant-dropdown-menu-item {
  padding: 0 !important;
  margin: 0 !important;
}
.registration-image {
  background-image: url(./assets/s2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100vh;
}
.video-preview {
  margin: 15px;
  aspect-ratio: 16 / 9;
  background-color: #bfbfbf;
  border-radius: 10px;
  max-height: 300px;
}
@media (max-width: 768px) {
  .video-preview {
    margin: 10px;
    aspect-ratio: 1 / 1;
    background-color: #bfbfbf;
    border-radius: 8px;
    max-height: 400px;
    width: 100%;
  }
}
