@import './global';

.caption-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 10px 6px;
  min-height: 34px;

  .caption {
    text-align: center;
    display: inline-block;
    border-radius: 6px;
    padding: 4px;
    background-color: $gray-800;
    color: $white;
    box-sizing: border-box;
  }
}
