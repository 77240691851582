@import './global';

.advanced-timeline {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 67px;
  width: 100%;
  min-width: 600px;
  border-radius: 5px;

  .side-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;

    .scale-text {
      margin-top: -6px;
      margin-bottom: -4px;
    }
  }

  .advanced-timeline-wrapper {
    position: relative;
    overflow: hidden;
    display: grid;
    height: 100%;
    width: 100%;
    border: 1px solid $gray-400;
    border-radius: 5px;

    .konva-instance {
      display: none;
    }

    .current-time-cursor,
    .mover {
      grid-row: 1;
      grid-column: 1;
    }

    .current-time-cursor {
      width: 2px;
      height: 100%;
      background-color: #68c896;
      pointer-events: none;
      z-index: 3;
    }

    .inside-timeline {
      margin-top: -60px;
    }

    .captions-side,
    .time-cursor-wrapper {
      width: 100%;
      overflow: hidden;
    }

    .captions-side {
      height: 32px;
    }

    .time-cursor-wrapper {
      display: grid;
      height: 64px;

      .user-time-cursor-wrapper {
        z-index: 2;
        height: 32px;
        cursor: pointer;

        .user-time-cursor {
          position: relative;
          width: 0px;
          height: 64px;
          pointer-events: none;
          will-change: transform;
          border-left: 2px dashed $red;
        }
      }
    }

    .times {
      border-bottom: 1px solid $gray-800;
    }

    .captions-side {
      background-color: $gray-100;

      .caption-item {
        display: inline-flex;
        position: absolute;
        height: 32px;
        justify-content: space-between;
        color: $white;
        text-shadow: 1px 1px $black;
        background-color: $black;
        border-radius: 4px;
        opacity: 0.8;
        overflow: hidden;
        cursor: pointer;
        transition-property: opacity, background-color, color;
        transition-duration: 0.2s;
        user-select: none;
        cursor: pointer;

        .start-pad {
          background: $orange;
        }

        .end-pad,
        .start-pad {
          display: inline-block;
          height: 100%;
          width: 6px;

          .inside {
            height: 100%;
            width: 6px;
          }
        }

        .content {
          width: calc(100% - 12px);
          overflow-wrap: break-word;

          .slide {
            position: absolute;
            width: calc(100% - 12px);
            height: 100%;
          }
        }

        &:hover {
          background: $gray-700;
        }

        &.selected {
          opacity: 1;
          background-color: $orange;
        }

        &.selected {
          .content {
            cursor: move;
          }

          .end-pad,
          .start-pad {
            cursor: ew-resize;
          }
        }
      }
    }
  }
}
