@import '../Video/global';

.circle-btn {
  width: auto;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  background-color: $gray-200;
  color: $gray-800;
  transition: 0.2s;
  display: flex !important;
  align-items: center;
  justify-content: center;
  .disabled {
    opacity: 0.3;
  }

  &:hover {
    color: $white;
    background-color: $gray-800;
    .btn-text {
      color: $white;
    }
  }
}
